import PropTypes from 'prop-types'
import { Box, Text, Image, Link, HStack } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'
import { CoverImage } from '@!/Store/Common/components'
import pointBackRewardCoinsPng from '@images/point_back_reward_coins.png'
import curvedArrowPng from '@images/curved_arrow.png'

export const BookTitleInfo = ({ title }) => {
  return (
    <Link href={`/store/books?book_title_id=${title.id}`} color="label.primary" textDecoration="none" fontWeight="600">
      <Text fontSize="sm">{title.name}</Text>
      <Box display="inline-block" justifyContent="center" m={2}>
        <HStack spacing={0} alignItems="flex-start">
          <Box minW="44px" h="63px" mr={2}>
            <CoverImage src={title.image_url} alt={title.name} />
          </Box>
          <Box fontSize="12px">
            <Box lineHeight="14px">
              <Text>{title.total_price.toLocaleString()}円</Text>
              <Text>(税込)</Text>
            </Box>
            <Text textAlign="left" pt={2}>
              全
              <Text as="span" color="label.accent">
                {title.total_volume}
              </Text>
              巻
            </Text>
          </Box>
        </HStack>
        <HStack spacing={0} alignItems="baseline" mt={1}>
          <Image src={buildAssetUrlString(curvedArrowPng)} w="26px" h="39px" />
          <Box position="relative">
            <Box position="absolute" bottom="15px">
              <Text color="label.accent" fontWeight="800" textAlign="left">
                {title.point_back.toLocaleString()}
              </Text>
            </Box>
            <Image src={buildAssetUrlString(pointBackRewardCoinsPng)} w="121px" h="27px" />
          </Box>
        </HStack>
      </Box>
    </Link>
  )
}

BookTitleInfo.propTypes = {
  title: PropTypes.object.isRequired,
}
