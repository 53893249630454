import { Stack, Spacer, Box, Heading, Text } from '@chakra-ui/react'
import { MenuLink } from './MenuLink'
import { MenuFooter } from './MenuFooter'
import { SafeSearch } from './SafeSearch'
import { PrimaryButton } from '@!/components/Button'

export const LoggedOutMenuBody = () => {
  const footerLinks = [
    { text: '問い合わせ', url: '/inquiry' },
    { text: 'お知らせ', url: '/announcements' },
    { text: 'ヘルプ', url: '/static_page/help' },
  ]

  return (
    <Stack>
      <Stack p={'24px'} spacing={0}>
        <Box borderBottom="solid 1px #E0E0E0" py={4}>
          <Heading fontSize="md" textAlign="center" as="h2" pb={2}>
            ログイン
          </Heading>
          <Text textAlign="center" fontSize="sm" my={2} wordBreak="keep-all">
            マンガBANGと同じアカウントが
            <wbr />
            ご利用いただけます。
          </Text>
          <PrimaryButton variant="outline" as="a" href="/user/login" my={2}>
            メールアドレスでログイン
          </PrimaryButton>
        </Box>
        <Box borderBottom="solid 1px #E0E0E0" py={4}>
          <Heading fontSize="md" textAlign="center" as="h2" pb={2}>
            会員登録
          </Heading>
          <PrimaryButton as="a" href="/user/login#/registrations" my={2}>
            新規会員登録（無料）
          </PrimaryButton>
        </Box>
        <Box borderBottom="solid 1px #E0E0E0" py={4}>
          <SafeSearch />
        </Box>
        <Box my={4} fontSize="sm">
          セーフサーチはTL/BLジャンルの作品を非表示にする機能です。
          <br />
          初期設定はONになっているため、作品を表示したい場合はOFFにしてください。
        </Box>
        <Spacer />
      </Stack>
      <MenuFooter>
        {footerLinks.map((link, index) => (
          <MenuLink key={`footer-${index}`} text={link.text} url={link.url} p={'12px 0'} fontSize={'.6825em'} textAlign={'center'} />
        ))}
      </MenuFooter>
    </Stack>
  )
}
